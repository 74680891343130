import React from "react";
import "./Pr.css";
import Images from "../../Data/Images";
import { faStar, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Pr = () => {
  return (
    <div className="Visa">
      <div className="about-main">
        <div data-aos="fade-right" className="about-content">
          <span>Visa Details</span>
          <div className="about-tags">
            <a href="/">Home</a>
            <p>/</p>
            <a href="Pr">PR</a>
          </div>
        </div>
      </div>

      <div className="visa-container">
        <div className="visa-side">
          <div data-aos="fade-right" className="visa-details-pr">
          <a href="Services">Tourist Visa<FontAwesomeIcon icon={faArrowRight} style={{color: "#0e5094",}} /></a>
            <a href="Studyvisa">Study Abroad<FontAwesomeIcon icon={faArrowRight} style={{color: "#0e5094",}} /></a>
            <a href="Pr">PR<FontAwesomeIcon icon={faArrowRight} style={{color: "#0e5094",}} /></a>
            <a href="Spousevisa">Spouse Visa<FontAwesomeIcon icon={faArrowRight} style={{color: "#0e5094",}} /></a>
            <a href="Airticket">Air Tickting - IATA Certified<FontAwesomeIcon icon={faArrowRight} style={{color: "#0e5094",}} /></a>
            <a href="Schoolingvisa">Schooling Visa<FontAwesomeIcon icon={faArrowRight} style={{color: "#0e5094",}} /></a>
            <a href="Tourpackages">Tour Packages<FontAwesomeIcon icon={faArrowRight} style={{color: "#0e5094",}} /></a>
            <a href="Grouptour">Group Tour<FontAwesomeIcon icon={faArrowRight} style={{color: "#0e5094",}} /></a>
          </div>
          <div data-aos="fade-right" className="visa-contact">
            <img src={Images.logo} alt="" />
            <span>
              Assured Approval
              <br />
              Guaranteed
            </span>
            <a href="Contact">Get in Touch</a>
          </div>
        </div>
        <div className="visa-rapper">
          <span1 data-aos="fade-up">Residence visa</span1>
          <br />
          <span data-aos="fade-right">
            The United Kingdom Offers Effortless Business Setup in the Country
          </span>
          <br />
          <p data-aos="fade-left">
            A residence visa stands as a gateway to building a new life in a
            foreign land. It’s more than just a permit; it’s an opportunity to
            immerse yourself in a new culture, embrace fresh opportunities, and
            become an integral part of a different community
          </p>
          <br />
          <p data-aos="fade-right">
            In the realm of global communication, a strong command of the
            English language is a bridge that connects cultures, and
            aspirations. The International English.
          </p>
          <br />
          <div data-aos="fade-up" className="visa-img">
            <img src={Images.visahome} alt="visahome" />
          </div>
          <br />
          <div className="evisa">
            <span1 data-aos="fade-up">How evisa work</span1>
            <div className="evisa-grid">
              <div data-aos="fade-right" className="evisa-main">
                <div className="evisa-img">
                  <img src={Images.one} alt="one" />
                </div>
                <div className="evisa-content">
                  <span>Choose your visa type</span>
                  <p>Determine the Visa type for your travel purpose.</p>
                </div>
              </div>
              <div data-aos="fade-left" className="evisa-main">
                <div className="evisa-img">
                  <img src={Images.too} alt="too" />
                </div>
                <div className="evisa-content">
                  <span>Contact our branches</span>
                  <p>Start your transaction by applying to our branches.</p>
                </div>
              </div>
              <div data-aos="fade-right" className="evisa-main">
                <div className="evisa-img">
                  <img src={Images.three} alt="three" />
                </div>
                <div className="evisa-content">
                  <span>Submit All Your Documents</span>
                  <p>Collect all the required documents the process.</p>
                </div>
              </div>
              <div data-aos="fade-left" className="evisa-main">
                <div className="evisa-img">
                  <img src={Images.four} alt="four" />
                </div>
                <div className="evisa-content">
                  <span>Passport delivery</span>
                  <p>
                    Receive your visa, which is finalized after application.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <span1 data-aos="fade-up">Residence visa benefits</span1>
          <br />
          <p data-aos="fade-right">
            A residence visa isn’t merely a document; it’s a key to unlocking a
            world of opportunities and experiences. With its myriad benefits, a
            residence visa opens, Here’s a closer look at the remarkable
            advantages that a residence visa brings:
          </p>
          <br />
          <div className="list-img">
            <div data-aos="fade-right" className="visa-list-img">
              <img src={Images.visa} alt="visa" />
            </div>
            <div data-aos="fade-left" className="visa-list">
              <ul>
                <li>
                  <FontAwesomeIcon icon={faStar} style={{ color: "#0e5094" }} />
                  Cultural Enrichmen
                </li>
                <li>
                  <FontAwesomeIcon icon={faStar} style={{ color: "#0e5094" }} />
                  Language Proficiency
                </li>
                <li>
                  <FontAwesomeIcon icon={faStar} style={{ color: "#0e5094" }} />
                  Quality of Life
                </li>
                <li>
                  <FontAwesomeIcon icon={faStar} style={{ color: "#0e5094" }} />
                  Career Opportunities
                </li>
                <li>
                  <FontAwesomeIcon icon={faStar} style={{ color: "#0e5094" }} />
                  Business Setup
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pr;
