import React from "react";
import "./Home.css";
import { useSpring, animated } from "react-spring";
import Images from "../../Data/Images";
import Faq from "../../Common/Faq/Faq";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function Number({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 400,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}

const Home = () => {
  return (
    <div className="Home">
      <div className="header">
        <div className="header-content">
          <span data-aos="fade-left">
          We are 100% Transparent <br /> & Loyal Company
          </span>
          <p data-aos="fade-left">Expert Guidance for a Seamless Immigration Journey</p>
          <div className="transparent">
            <div data-aos="zoom-out" className="points">
              <span1>We Dont't Take Original Documents of Clients.</span1>
            </div>
            <div data-aos="zoom-out" className="points">
              <span1>We Don't Take College/Uni Fees in our Account.</span1>
            </div>
            <div data-aos="zoom-out" className="points">
              <span1>We Do Live Visa Submission Process and Handover a Copy to Client.</span1>
            </div>
            <div data-aos="zoom-out" className="points">
              <span1>We handover Clients their file to read and then sign it.</span1>
            </div>
          </div>
          <p1 data-aos="fade-left">MOTO OF OUR COMPANY IS HONESTY FIRST | GIVE US AN OPPORTUNITY TO SERVE YOU <br /> THEN YOU WILL FEEL THE DIFFERNCE</p1>
          <div data-aos="fade-left" className="header-btns">
            <a href="Contact">Book Appointment</a>
            <a href="About" className="onlybtn">
              Read Story
            </a>
          </div>
        </div>
      </div>

      <div className="visatypes">
        <div className="visa-back">
          <div data-aos="fade-left" className="visa-heading">
            <span>
              Visa types and eligibility <br /> assessment
            </span>
          </div>
          <div className="visa-grid">
            <div className="grid-type">
              <div data-aos="fade-right" className="type">
                <img src={Images.study} alt="tourist" />
                <span>Study Visa</span>
                <p>
                  Embarking on a journey of higher education in a foreign
                  country opens doors...
                </p>
              </div>
              <div data-aos="fade-left" className="type">
                <img src={Images.tourist} alt="commercial" />
                <span>Tourist/Visitor Visa</span>
                <p>
                  Visit new places to discover with a Tourist Visa...
                </p>
              </div>
            </div>
            <div className="grid-type">
              <div data-aos="fade-right" className="type">
                <img src={Images.commercial} alt="study" />
                <span>Spouse Visa</span>
                <p>
                We give our 100% to reunite you with your partner...
                </p>
              </div>
              <div data-aos="fade-left" className="type">
                <img src={Images.pr} alt="pr" />
                <span>PR</span>
                <p>
                  Expert Guidance for a Seamless Immigration Journey and Better
                  Future...
                </p>
              </div>
              <div data-aos="fade-right" className="type">
                <img src={Images.Airticket} alt="Airticket" />
                <span>Air Tickets By IATA</span>
                <p>
                Enjoy the air tickets with us at lowest rates in market...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="guidance">
        <div className="guidance-main">
          <div data-aos="fade-up" className="guidance-heading">
            <span>
              Dependable and Trustworthy Visa &<br /> Immigration Guidance
            </span>
            <p>
              Our team of seasoned professionals understands the complexities of
              immigration laws and visa procedures.
            </p>
          </div>
          <div className="guidance-grid">
            <div data-aos="fade-right" className="process">
              <div className="process-img">
                <img src={Images.one} alt="one" />
              </div>
              <div className="process-content">
                <span>Choose your visa type</span>
                <p>Determine the Visa type for your travel purpose.</p>
              </div>
            </div>
            <div data-aos="fade-left" className="process">
              <div className="process-img">
                <img src={Images.too} alt="too" />
              </div>
              <div className="process-content">
                <span>Contact our branches</span>
                <p>Start your transaction by applying to our branches.</p>
              </div>
            </div>
            <div data-aos="fade-right" className="process">
              <div className="process-img">
                <img src={Images.three} alt="three" />
              </div>
              <div className="process-content">
                <span>Submit All Your Documents</span>
                <p>Collect all the required documents the process.</p>
              </div>
            </div>
            <div data-aos="fade-left" className="process">
              <div className="process-img">
                <img src={Images.four} alt="four" />
              </div>
              <div className="process-content">
                <span>Passport delivery</span>
                <p>Receive your visa, which is finalized after application.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="success">
        <div data-aos="fade-up" className="success-heading">
          <span>
            Discovering Our Biggest Successes: The Stories
            <br /> Behind Our Great Achievements
          </span>
          <p>
            Embarking on a journey to reunite families, we recently had the
            privilege of assisting a couple in securing their spouse's visa.
          </p>
        </div>
        <div className="success-grid">
          <div data-aos="fade-right" className="grid-process">
            <div className="grid-img">
              <img src={Images.categories} alt="" />
            </div>
            <div className="success-content">
              <span>
                <Number n={20} />+
              </span>
              <p>Visa Categories</p>
            </div>
          </div>
          <div data-aos="fade-left" className="grid-process1">
            <div className="grid-img">
              <img src={Images.process} alt="" />
            </div>
            <div className="success-content">
              <span>
                <Number n={30} />
                k+
              </span>
              <p>Visa Process</p>
            </div>
          </div>
          <div data-aos="fade-right" className="grid-process2">
            <div className="grid-img">
              <img src={Images.project} alt="" />
            </div>
            <div className="success-content">
              <span>
                <Number n={40} />
                k+
              </span>
              <p>Successful Project</p>
            </div>
          </div>
          <div data-aos="fade-left" className="grid-process3">
            <div className="grid-img">
              <img src={Images.consultants} alt="" />
            </div>
            <div className="success-content">
              <span>
                <Number n={180} />
                k+
              </span>
              <p>Pro Consultants</p>
            </div>
          </div>
        </div>
      </div>

      <div className="home-faq">
        <Faq />
      </div>
    </div>
  );
};

export default Home;
