import React from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faMailBulk,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <div className="Contact">
      <div className="about-main">
        <div data-aos="fade-right" className="about-content">
          <span>Contact Us</span>
          <div className="about-tags">
            <a href="/">Home</a>
            <p>/</p>
            <a href="Contact">Contact</a>
          </div>
        </div>
      </div>

      <div className="branches">
        <div data-aos="fade-right" className="address">
          <FontAwesomeIcon icon={faAddressBook} className="i" />
          <span>Address</span>
          <a href="https://maps.app.goo.gl/jT8xaPp7zs1BWgVD6">
            Main Market, Street No. 8, Old Bishan Nagar, Patiala
          </a>
        </div>
        <div data-aos="fade-up" className="address">
          <FontAwesomeIcon icon={faPhone} className="i" />
          <span>Amandeep Narang (Director)</span>
          <a href="tel: 9574895790">+91 95748 95790</a>
        </div>
        <div data-aos="fade-left" className="address">
          <FontAwesomeIcon icon={faPhone} className="i" />
          <span>Office (Documents Submission)</span>
          <a href="tel: 9574895792">+91 95748 95792</a>
        </div>
      </div>

      <div className="emails">
        <div data-aos="fade-up" className="email">
          <FontAwesomeIcon icon={faMailBulk} className="icon" />
          <span>Email For Canada</span>
          <a href="mailto: canada@spaceinternationals.com">
            canada@spaceinternationals.com
          </a>
        </div>
        <div data-aos="fade-up" className="email">
          <FontAwesomeIcon icon={faMailBulk} className="icon" />
          <span>Email For Australia</span>
          <a href="mailto: aus@spaceinternationals.com">
            aus@spaceinternationals.com
          </a>
        </div>
        <div data-aos="fade-up" className="email">
          <FontAwesomeIcon icon={faMailBulk} className="icon" />
          <span>Email For Europe</span>
          <a href="mailto: europe@spaceinternationals.com">
            europe@spaceinternationals.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
