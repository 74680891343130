import React from "react";
import "./Testimonials.css";
import Images from "../../Data/Images";
// import { Testodata } from "../../Data/Testodata";

const Testimonials = () => {
  // const data = Testodata;
  // const [open, setOpen] = useState();

  // const toggle = (i) => {
  //     if (open === i) {
  //         return setOpen(null)
  //     }

  //     setOpen(i)
  // }

  return (
    <div className="Testimonials">
      <div className="about-main">
        <div data-aos="fade-right" className="about-content">
          <span>Testimonials</span>
          <div className="about-tags">
            <a href="/">Home</a>
            <p>/</p>
            <a href="Testimonials">Testimonials</a>
          </div>
        </div>
      </div>

      <div className="testimonials-grid">
        <div data-aos="fade-right" className="testo-grid">
          <div className="testo-img-cont">
            <div className="testo-img">
              <img src={Images.avatar1} alt="" />
            </div>
            <div className="testo-cont">
              <span>Elisa Doward</span>
              <p>Canadian Student</p>
            </div>
          </div>
          <div className="testo-line"></div>
          <div className="testo-content">
            <p>
              Elisa Doward, a Canadian university student, embarked on a journey
              abroad with the help of E.visa Company Service. With their
              support, she successfully pursued her education overseas, gaining
              valuable experiences and knowledge
            </p>
            <br />
            <p>
              Upon returning to Canada, Elisa's determination and E.visa Company
              Service's guidance led her to a thriving career in Seattle. She
              now excels in her field, serving as an inspiration for others
              seeking success in there life as well.
            </p>
            <br />
            <p>
              Elisa's story showcases the power of determination and expert
              support in achieving one's dreams.
            </p>
          </div>
        </div>
        <div data-aos="fade-left" className="testo-grid">
          <div className="testo-img-cont">
            <div className="testo-img">
              <img src={Images.avatar2} alt="" />
            </div>
            <div className="testo-cont">
              <span>Elisa Doward</span>
              <p>Canadian Student</p>
            </div>
          </div>
          <div className="testo-line"></div>
          <div className="testo-content">
            <p>
              Elisa Doward, a Canadian university student, embarked on a journey
              abroad with the help of E.visa Company Service. With their
              support, she successfully pursued her education overseas, gaining
              valuable experiences and knowledge
            </p>
            <br />
            <p>
              Upon returning to Canada, Elisa's determination and E.visa Company
              Service's guidance led her to a thriving career in Seattle. She
              now excels in her field, serving as an inspiration for others
              seeking success in there life as well.
            </p>
            <br />
            <p>
              Elisa's story showcases the power of determination and expert
              support in achieving one's dreams.
            </p>
          </div>
        </div>
        <div data-aos="fade-right" className="testo-grid">
          <div className="testo-img-cont">
            <div className="testo-img">
              <img src={Images.avatar3} alt="" />
            </div>
            <div className="testo-cont">
              <span>Elisa Doward</span>
              <p>Canadian Student</p>
            </div>
          </div>
          <div className="testo-line"></div>
          <div className="testo-content">
            <p>
              Elisa Doward, a Canadian university student, embarked on a journey
              abroad with the help of E.visa Company Service. With their
              support, she successfully pursued her education overseas, gaining
              valuable experiences and knowledge
            </p>
            <br />
            <p>
              Upon returning to Canada, Elisa's determination and E.visa Company
              Service's guidance led her to a thriving career in Seattle. She
              now excels in her field, serving as an inspiration for others
              seeking success in there life as well.
            </p>
            <br />
            <p>
              Elisa's story showcases the power of determination and expert
              support in achieving one's dreams.
            </p>
          </div>
        </div>
        <div data-aos="fade-left" className="testo-grid">
          <div className="testo-img-cont">
            <div className="testo-img">
              <img src={Images.avatar4} alt="" />
            </div>
            <div className="testo-cont">
              <span>Elisa Doward</span>
              <p>Canadian Student</p>
            </div>
          </div>
          <div className="testo-line"></div>
          <div className="testo-content">
            <p>
              Elisa Doward, a Canadian university student, embarked on a journey
              abroad with the help of E.visa Company Service. With their
              support, she successfully pursued her education overseas, gaining
              valuable experiences and knowledge
            </p>
            <br />
            <p>
              Upon returning to Canada, Elisa's determination and E.visa Company
              Service's guidance led her to a thriving career in Seattle. She
              now excels in her field, serving as an inspiration for others
              seeking success in there life as well.
            </p>
            <br />
            <p>
              Elisa's story showcases the power of determination and expert
              support in achieving one's dreams.
            </p>
          </div>
        </div>
        <div data-aos="fade-right" className="testo-grid">
          <div className="testo-img-cont">
            <div className="testo-img">
              <img src={Images.avatar5} alt="" />
            </div>
            <div className="testo-cont">
              <span>Elisa Doward</span>
              <p>Canadian Student</p>
            </div>
          </div>
          <div className="testo-line"></div>
          <div className="testo-content">
            <p>
              Elisa Doward, a Canadian university student, embarked on a journey
              abroad with the help of E.visa Company Service. With their
              support, she successfully pursued her education overseas, gaining
              valuable experiences and knowledge
            </p>
            <br />
            <p>
              Upon returning to Canada, Elisa's determination and E.visa Company
              Service's guidance led her to a thriving career in Seattle. She
              now excels in her field, serving as an inspiration for others
              seeking success in there life as well.
            </p>
            <br />
            <p>
              Elisa's story showcases the power of determination and expert
              support in achieving one's dreams.
            </p>
          </div>
        </div>
        <div data-aos="fade-left" className="testo-grid">
          <div className="testo-img-cont">
            <div className="testo-img">
              <img src={Images.avatar6} alt="" />
            </div>
            <div className="testo-cont">
              <span>Elisa Doward</span>
              <p>Canadian Student</p>
            </div>
          </div>
          <div className="testo-line"></div>
          <div className="testo-content">
            <p>
              Elisa Doward, a Canadian university student, embarked on a journey
              abroad with the help of E.visa Company Service. With their
              support, she successfully pursued her education overseas, gaining
              valuable experiences and knowledge
            </p>
            <br />
            <p>
              Upon returning to Canada, Elisa's determination and E.visa Company
              Service's guidance led her to a thriving career in Seattle. She
              now excels in her field, serving as an inspiration for others
              seeking success in there life as well.
            </p>
            <br />
            <p>
              Elisa's story showcases the power of determination and expert
              support in achieving one's dreams.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
