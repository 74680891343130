import React, { useState } from "react";
import "./Faq.css";
import Images from "../../Data/Images";
import { Faqdata } from "../../Data/Faqdata";

const Faq = () => {
  const data = Faqdata;
  const [open, setOpen] = useState();

  const toggle = (i) => {
    if (open === i) {
      return setOpen(null);
    }
    setOpen(i);
  };

  return (
    <div className="faq">
      <div className="faq-right">
        <div data-aos="fade-up" className="faq-heading">
          <span>Common questions answered</span>
          <p>
            At the heart of our commitment to providing <br /> exceptional
            immigration solutions stands our trusted
          </p>
        </div>
        <div data-aos="fade-up" className="faq-img">
          <img src={Images.faq} alt="faq" />
        </div>
      </div>
      <div data-aos="fade-up" className="faq-left">
        <div className="faq-wrapper">
          {data.map((item, i) => {
            return (
              <div className="faq-accordion" onClick={() => toggle(i)}>
                <div className="faq-title">
                  <h2>{item.Question}</h2>
                  <span>{open === i ? "-" : "+"}</span>
                </div>
                <div className={open === i ? "content show" : "content"}>
                  {" "}
                  {item.Answer}{" "}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Faq;
