import React from "react";
import "./About.css";
import { useSpring, animated } from "react-spring";
import Images from "../../Data/Images";

function Number({ n }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 400,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}

const About = () => {
  return (
    <div className="About">
      <div className="about-main">
        <div data-aos="fade-right" className="about-content">
          <span>About Us</span>
          <div className="about-tags">
            <a href="/">Home</a>
            <p>/</p>
            <a href="About">About Us</a>
          </div>
        </div>
      </div>

      <div className="about-mc">
        <span>Our Story</span>
        <br />
        <p>
          Space Internationals newly formed online platform simplifies the study
          abroad search, application, and acceptance process by connecting
          international students, recruitment teams, freelancers and academic
          institutions on one platform.
        </p>
        <br />
        <p>
          Founded in 2018 by Narang family i.e. Amandeep Narang and Kusum
          Narang, we’ve built partnerships with 1,000+ primary, secondary, and
          post-secondary educational institutions, and work with recruitment
          teams to drive diversity on campuses across Canada, the United States,
          the United Kingdom, Australia, and Ireland.
        </p>
        <br />
        <p>
          Space Internationals is growing to become the world’s largest online
          platform for international student recruitment, assisting thousands of
          students to be successful in their life and can get best results in
          with their educational journeys as well as in their dream carrier.
          Year 2024, will be the Space Internationals named as one of the
          fastest-growing technology companies in India as well as at Glob
          level.
        </p>
        <br />
        <p>
          Our team has grown quickly over the past six years, and we now have
          100+ team members across the India and globe. The Space Internationals
          headquarters is located in Patiala, Punjab, India with representatives
          and is going to expend their team network in more than 30 other
          countries including India, Canada, China, Vietnam, the Philippines,
          Nepal, Bangladesh, the United Kingdom, Australia, and the United
          States.
        </p>
        <br />
        <span>The Leadership Team</span>
        <br />
        <p>Meet the co-founder and leaders of Space Internationals:</p>
        <br />
        <span1>Amandeep Narang.</span1>
        <br />
        <p>
          After facing many challenges and obstacles on their own study abroad
          journeys, the Amandeep Narang saw an opportunity to break down
          barriers and democratize education. Together they created Space
          Internationals to make their dream to educate the world a reality did
          their work in such a transparent way that now Space Internationals can
          say that this company is 100% transparent company to safeguard their
          students, their recruitment teams, freelancers, educational
          institutions and their own staff members.
        </p>
        <br />
        <p>
          Today Amandeep Narang built an expanded leadership team with
          collective experience and expertise in higher education, technology,
          security, HR, finance, marketing, customer success, and more.
        </p>
        <br />
        <p>That’s the reason we can say that</p>
        <br />
        <p1>MOTO OF OUR COMPANY IS HONESTY FIRST | VISIT US THEN YOU WILL FEEL DIFFERENCE.</p1>
      </div>

      <div className="about-main-content">
        <div data-aos="fade-right" className="about-span">
          <span>
            Committed to Your Visa <br /> Success - About us
          </span>
        </div>
        <div data-aos="fade-left" className="about-para">
          <p>
            We deliver budget-friendly visa solutions, removing
            <br /> financial barriers from your journey. Our goal is to provide
            <br /> quality services at reasonable rates.
          </p>
        </div>
      </div>

      <div className="about-photo">
        <div data-aos="fade-up" className="photo">
          <img src={Images.About} alt="About-Img" />
        </div>
        <div className="photo-content">
          <div data-aos="fade-right" className="container-about">
            <div className="container-content">
              <span>
                <Number n={20} />+
              </span>
              <p>
                We Have Worked
                <br /> With Clients
              </p>
            </div>
          </div>
          <div data-aos="fade-up" className="container-about">
            <div className="container-content">
              <span>
                <Number n={100} />%
              </span>
              <p>
                Successful Visa
                <br /> Process Rate
              </p>
            </div>
          </div>
          <div data-aos="fade-left" className="container-about">
            <div className="container-content">
              <span>
                <Number n={1} />
                Day
              </span>
              <p>
                Application Approval
                <br /> Processing Time
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="about-photo-content">
        <div data-aos="fade-right" className="main-about-content">
          <span>
            Guiding Your Path with Our
            <br /> Immigration Mission
          </span>
          <p>
            We're here to simplify immigration complexities, guiding you to
            <br /> success. Our mission is to unite families, open
            opportunities, and
            <br /> make your journey enriching. Your dreams are our focus on the
            path
            <br /> to a brighter future.
          </p>
        </div>
        <div data-aos="fade-left" className="main-about-photo">
          <img src={Images.Mission} alt="Mission" />
        </div>
      </div>

      <div className="about-photo-content">
        <div data-aos="fade-right" className="main-about-photo">
          <img src={Images.Mission} alt="Mission" />
        </div>
        <div data-aos="fade-left" className="main-about-content">
          <span>
            Our Immigration
            <br /> Service history
          </span>
          <p>
            Our history began with a vision to make the immigration process
            <br /> smoother and more accessible for individuals and families
            around
            <br /> the world. With a deep understanding of the challenges that
            <br /> accompany moving to a new country,
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
