import "./App.css";
import Aos from 'aos';
import { useEffect } from 'react';
import "aos/dist/aos.css";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Visa from "./Pages/Visa/Visa";
import Testimonials from "./Pages/Testimonials/Testimonials";
import { Route, Routes } from "react-router-dom";
import BackToTop from "./Common/BacktoTop/BacktoTop";
import Studyvisa from "./Pages/Studyvisa/Studyvisa";
import Spousevisa from "./Pages/Spousevisa/Spousevisa";
import Schoolingvisa from "./Pages/Schoolingvisa/Schoolingvisa";
import Pr from "./Pages/Pr/Pr";
import Grouptour from "./Pages/Grouptour/Grouptour";
import Tourpackages from "./Pages/Tourpackages/Tourpackages";
import Airticket from "./Pages/Airticket/Airticket";

function App() {

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="About" element={<About />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="Services" element={<Visa />} />
        <Route path="Testimonials" element={<Testimonials />} />
        <Route path="Studyvisa" element={<Studyvisa />} />
        <Route path="Spousevisa" element={<Spousevisa />} />
        <Route path="Schoolingvisa" element={<Schoolingvisa />} />
        <Route path="Pr" element={<Pr />} />
        <Route path="Grouptour" element={<Grouptour />} />
        <Route path="Tourpackages" element={<Tourpackages />} />
        <Route path="Airticket" element={<Airticket />} />
      </Routes>
      <BackToTop />
      <Footer />
    </>
  );
}

export default App;
