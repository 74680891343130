import React from 'react'
import "./Navbar.css";
import Images from '../../Data/Images'
import {GiHamburgerMenu, GiCrossMark} from 'react-icons/gi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'

const Navbar = () => {

    const[togglemenu, settogglemenu] = React.useState(false);

  return (
    <nav className="navbar">
        <div className="nav-logo">
            <img src={Images.logo} alt="logo" />
        </div>

        <div className="nav-links">
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="About">About</a></li>
            <li><a href="Services">Services</a></li>
            <li><a href="Testimonials">Testimonials</a></li>
            <li><a href="Contact">Contact</a></li>
        </ul>
        </div>

        <div className="navbar-smallscreen">
        <GiHamburgerMenu color='#0e5094' className="navbar-menu" fontsize={50} onClick={() => {settogglemenu(true)}} />
        {togglemenu && (
          <div className="navbar-smallscreen_overlay flex__center slide-bottom">
            <GiCrossMark color="#ffffff" className="overlay__close" onClick={() => {settogglemenu(false)}} />
            <ul className="navbar-smallscreen_links">
              <li><a href="/" onClick={() => {settogglemenu(false)}}>Home</a></li>
              <li><a href="About" onClick={() => {settogglemenu(false)}}>About</a></li>
              <li><a href="Visa" onClick={() => {settogglemenu(false)}}>Visa</a></li>
              <li><a href="Testimonials" onClick={() => {settogglemenu(false)}}>Testimonials</a></li>
              <li><a href="Contact" onClick={() => {settogglemenu(false)}}>Contact</a></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Navbar