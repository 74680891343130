import logo from "../Assets/logo.png";
import newsletter from "../Assets/newsletter.png";
import faq from "../Assets/faq_img.png";
import tourist from "../Assets/1.png";
import study from "../Assets/2.png";
import pr from "../Assets/3.png";
import commercial from "../Assets/4.png";
import one from "../Assets/1_1.png";
import too from "../Assets/2_1.png";
import three from "../Assets/3_1.png";
import four from "../Assets/4_1.png";
import categories from "../Assets/1_2.png";
import process from "../Assets/2_2.png";
import project from "../Assets/3_2.png";
import consultants from "../Assets/4_2.png";
import About from "../Assets/about_img3.jpg";
import Mission from "../Assets/mission.jpg";
import visa from "../Assets/visa.jpg";
import visahome from "../Assets/visa-home.jpg";
import avatar1 from "../Assets/avatar1.png";
import avatar2 from "../Assets/avatar2.png";
import avatar3 from "../Assets/avatar3.png";
import avatar4 from "../Assets/avatar4.png";
import avatar5 from "../Assets/avatar5.png";
import avatar6 from "../Assets/avatar6.png";
import avatar7 from "../Assets/avatar7.png";
import back from "../Assets/back.png";
import background from "../Assets/background.png";
import Airticket from "../Assets/airticket.png";

export default {
  logo,
  newsletter,
  faq,
  tourist,
  study,
  pr,
  commercial,
  one,
  too,
  three,
  four,
  categories,
  process,
  project,
  consultants,
  About,
  Mission,
  visa,
  visahome,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  back,
  background,
  Airticket,
};
