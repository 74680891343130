export const Faqdata = [
    {
      Question:
        "What services do you offer?",
      Answer:
        "We offer comprehensive immigration and visa consulting services, including visa application assistance, document preparation, Comprehensive Visa Assistance,Visa Category Expertise, Transparency and Communication",
    },
    {
      Question: "What is the consultation process like?",
      Answer:
        "If you want to travel to a country that does not have a visa regime similar to your own, you’ll need a visa. Many nations have visa regulations and agreements that allow inhabitants of those countries to travel freely between them without requiring a visa.",
    },
    {
      Question: "How much do your services cost?",
      Answer:
        "A country’s visa policy is a set of rules that determines who is allowed and who is not allowed to enter the country. The policy may allow passport holders from one nation to enter without a visa, but not those from another. The majority of visa rules are bilateral, meaning that two countries will enable their nationals to travel visa-free, however this is not always the case. When it comes to setting visa policies, there are no hard and fast laws. However, diplomatic links with the other country, the history of illegal immigration from the country, and cost and tourism factors are some determining factors.",
    },
    {
      Question: "How do I get started with your services?",
      Answer:
        "Visa limitations are in place to keep track of and control the flow of visitors in and out of a nation, as well as to prevent illegal immigration and other criminal activity. By requiring travelers to apply for a visa, the authorities are able to thoroughly scrutinize potential visitors. Individuals may, for example, attempt to migrate to a country with superior economic chances in order to work there illegally.",
    },
    {
      Question: "What is your success rate with visa applications?",
      Answer:
        "There are different types of Visas that are available based on your requirement and purpose of travel overseas such as Student Visa, Work Visa, Investor Visa, Business Visa, Dependent Visa, and Visitor Visa, etc.",
    },
  ];
  