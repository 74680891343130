import React, { useRef, useState } from "react";
import "./Footer.css";
import emailjs from '@emailjs/browser';
import Images from "../../Data/Images";

const Footer = () => {

  const formRef = useRef(1);
  const [done, setDone] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "",
        "",
        formRef.current,
        ""
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="Footer">
      <div data-aos="fade-up" className="footer-newsletter">
        <div className="newsletter">
          <p>Newsletter</p>
          <span>
            Subscribe to the free newsletter to receive<br/> the latest news & case
            studies!
          </span>
          <div className="formletter">
            <input type="text" placeholder="Your E-mail Address" />
            <button>Subscribe</button>
          </div>
        </div>
        <div className="newsletter-img">
          <img src={Images.newsletter} alt="" />
        </div>
      </div>

      <div data-aos="fade-up" className="footer-form">
        <div className="contact-form">
          <div className="heading-form">
            <p>Contact Us</p>
            <br />
            <span>Do you have questions or<br /> want more information?</span>
          </div>
          <div className="form">
            <form action="" ref={formRef} onSubmit={handleSubmit}>
              <div className="too-inputs">
                <input type="text" name="Name" placeholder="Name" />
                <input type="text" name="Email" placeholder="Email" />
              </div>
              <div className="too-inputs">
                <input type="text" name="Visa" placeholder="Visa Type" />
                <input type="text" name="Mobile" placeholder="Number" />
              </div>
              <div className="text-area">
                <textarea
                  name="Message"
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Write your Message..."
                ></textarea>
              </div>
              <div className="form-btn">
                <button>Send Message</button>
              </div>
            </form>
          </div>
        </div>
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6886.810550138779!2d76.413193!3d30.33943!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3910285efe9e8985%3A0x8dbbef296888cdfa!2sSPACE%20INTERNATIONALS!5e0!3m2!1sen!2sin!4v1713471258263!5m2!1sen!2sin"
            width="400"
            height="600"
            className="map"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div className="footer-links">
        <div className="footer-info">
          <span>
            Do you have questions or went more information? Contact us now
          </span>
          <div className="logo-info">
            <a href="tel: 9574895790">+91 9574895790</a>
          </div>
          <div className="logo-info">
            <a href="Mailto: info@spaceinternational.com">info@spaceinternational.com</a>
          </div>
        </div>
        <div className="f-links">
          <div className="link-heading">
            <span>Explore Link</span>
          </div>
          <div className="link-tags">
            <a href="About">+ About Us</a>
            <a href="Services">+ Services</a>
            <a href="Testimonials">+ Testimonials</a>
            <a href="Contact">+ Contact</a>
          </div>
        </div>
        <div className="f-links">
          <div className="link-heading">
            <span>Services</span>
          </div>
          <div className="link-tags">
            <a href="Studyvisa">+ Study Visa</a>
            <a href="Visa">+ Tourist Visa</a>
            <a href="Pr">+ PR</a>
            <a href="Spousevisa">+ Spouse Visa</a>
          </div>
        </div>
        {/* <div className="f-links">
          <div className="link-heading">
            <span>Our Branches</span>
          </div>
          <div className="link-tags"></div>
        </div> */}
      </div>

      <div className="footer-line"></div>

      <div className="footer-bottom">
        <div className="copyright">
          <span>Copyright © 2024 spaceinternational All rights reserved.</span>
        </div>
        <div className="copyright">
          <span>
            Developed by <a href="">Coding Eggs</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
